import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import FullScreenLoader from '@shared/ui/progress-loaders/FullScreenLoader';
import { useAppSelector } from '@app/store/Hooks';
import { useLazyGetMeQuery } from '@shared/api/current-user';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { IsDefined } from '@utils/js-ts';
import UnauthorizedPage from './UnauthorizedPage';
const RouteAuthCheckWrapper = ({ children }) => {
    const { navigate, routes } = useAppNavigation();
    const [getMeReq, { isError, isLoading: isLoadingUserData, data }] = useLazyGetMeQuery();
    const { auth } = useAppSelector(state => state.auth);
    useEffect(() => {
        if ((auth === null || auth === void 0 ? void 0 : auth.userId) && !IsDefined(data)) {
            getMeReq();
        }
    }, [auth, data, getMeReq]);
    useEffect(() => {
        if (!auth || isError) {
            navigate(routes.ROOT);
        }
    }, [auth, isError, data, navigate, routes]);
    if (!auth || isError) {
        return _jsx(UnauthorizedPage, {});
    }
    if (!auth || isLoadingUserData) {
        return _jsx(FullScreenLoader, {});
    }
    return (children);
};
export default RouteAuthCheckWrapper;
